import React from 'react'
import axios from 'axios'
import {Modal,ModalBody,ModalHeader,ModalFooter, Table,TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,CardHeader,CardBody, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SimpleDateTime  from 'react-simple-timestamp-to-date'
import moment from 'jalali-moment'
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import SimpleLineIcon from 'react-simple-line-icons'
import SearchInput, {createFilter} from 'react-search-input'
import Loader from '../../landing/loader'
import cookie from 'react-cookies'
import {Helmet} from 'react-helmet'
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { ColDef } from "ag-grid-community";
import Toast from 'awesome-toast-component'

import { ExcelExportModule } from "@ag-grid-enterprise/excel-export";import {withTranslation} from 'react-i18next'
class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.data = []
    this.state = {
      data : [],
      orders : [],
      buy:[],
      sell : [],
      deposits : [],
      withdraws : [],
      order : [],
      message : 'در حال دریافت اطلاعات ',
      all : [],
      activetab : 0,
      modal : false,
      backdrop : false,
      keyboard : true,
      loader : true,
    }
    this.setactive = this.setactive.bind(this)
  }
  componentWillMount(){
    if(!cookie.load('uid')){
      this.props.history.push('/auth/login')
      return
    }
    this.get()
  }
  // setactive =(x)=>{
  //   this.setState({activetab : x})
  // }
  setactive =(e)=>{
    this.setState({activetab : e})
    switch (e) {
      // .filter(item => item.symbolName.includes(this.state.searchTerm));
      case 0:
        this.data = this.state.all.filter(item => item.side.includes('300014') ||  item.side.includes('300005'))
        break;
      case 1:
        this.data = this.state.all.filter(item => item.side.includes('300015'))
        break;
      case 2:
        this.data = this.state.all.filter(item => item.type === '300001')
        break;
      case 3:
        this.data = this.state.all.filter(item => item.type === '300002')
        break;
      case 4:
        this.data = this.state.orders.openorders
        break;
      case 5:
        this.data = this.state.orders.limitorders
        break;

      default:
      this.data = this.state.all

    }
  }
  get = ()=>{
    axios.get('/api/transactions/').then(response=>{
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
      }
      this.setState({loader : false})
      if(response.data.all.length === 0){
        this.setState({message : 'تراکنشی برای نمایش وجود ندارد .'})
      }
      this.data  = response.data.all.filter(item => item.side === '300014' || item.side === '300005')
      this.setState({ all : response.data.all , orders : response.data.market_orders})
    })
  }
  toggle = (i)=>{
    this.setState({activetab : i})
  }
  jdate = (x)=>{
    var x = parseInt(x * 1000)
    var today = new Date(x);
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  let Today = yyyy + '/' + mm + '/' + dd;
  return(
    <>{moment(Today, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</>
  )

  }
numeric =(x)=>{
  return(
  <NumericFormat value={x} thousandSeparator="," displayType="text"  className='text-dark' />

  )
}

 backdrop = (e) => {

  this.setState({backdrop : !this.state.backdrop})
}
 togglemodal = (e) => {

  this.setState({modal : !this.state.modal})
}

 changeKeyboard = (e) => {
  this.setState({ keyboard : e.currentTarget.checked})
}
getx =(i)=>{
  let type = i.type
  this.setState({order : i , modal : true})
  // axios.get('/api/transactions/get_detail/' +i.orderid).then(response=>{
  //   this.setState({order : response.data})
  // })
}
setcolor =(i)=>{
  let s = i.status
  switch (s) {
    case 'تکمیل شده':
      return 'green'
      break;
    case 'در صف واریز':
      return 'blue'
      break;
    case 'در حال انجام':
      return 'orange'
      break;
    case 'لغو شده':
      return 'red'
      break;
    default:

  }
}
toggleclose =()=>{
  this.setState({modal : false})
}
toggle =()=>{
  this.setState({modal : false})
}

closeorder =(i)=>{
    const {t} = this.props


  axios.get('/api/trade/closeorder/'+i).then(response=>{
    if(response.data.status === 'success'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
    return
    }else{
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
    }
  })
this.get()
}
Getstatus = (params) =>{
  return this.props.getstatus(params.data.status)
}
  render(){

    const theme = document.body.getAttribute('class')
    // console.log(this.data);
    const {t} = this.props

    const defaultColDef = ()=> {
      return {
        filter: "agTextColumnFilter",
        floatingFilter: true,
      };
    };
    const getRowStyle = params => {
      console.log(params.data);
        if (params.data.side === 'خرید') {
            return { background: 'purple' };
        }else{
          return { background: 'indigoi' };
        }
    };
    const onBtExport = () => {
      this.gridRef.current.api.exportDataAsCsv(this.data);
    }
const i = this.state.order
const CustomButtonComponent = (id) => {

  // console.log(id.data.id);
  // history.push('/admin/User-detail/'+id.data.userid)
};

  const cellStyle = (params) => {
    if (params.data.status === '300012') {
        return { color: 'green' };
    }else{
      return { color: 'red' };
    }
};
  const cellStyle2 = (params) => {
    if (params.data.side === 'خرید' || params.data.side === 'واریز') {
        return { color: 'green' };
    }else{
      return { color: 'red' };
    }
};

const Slugrender = (params) =>{
  return <div className='d-flex'>
  <p className={params.data.basename+'-icon small-icon mt-1 ltr'}></p>
  <p className='mr-3 ml-2'>{params.data.basename}</p>
  </div>
}
const Marketrender = (params) =>{
  return <div className='d-flex'>
  <p className={params.data.basename+'-icon small-icon mt-1 ltr'}></p>
  <p className={params.data.quotename+'-icon quote-icon small-icon mt-1 ltr'}></p>
  <p className='mr-3 ml-2'>{params.data.market}</p>
  </div>
}
const Renderbuysell = (params) =>{

}
const Quoterender = (params) =>{
  return <div className='d-flex'>
  <p className={params.data.quotename+'-icon small-icon mt-1 ltr'}></p>
  <p className='mr-3 ml-2'>{params.data.quoteamount}</p>
  </div>
}
const Baserender = (params) =>{
  return <div className='d-flex'>
  <p className={params.data.basename+'-icon small-icon mt-1 ltr'}></p>
  <p className='mr-3 ml-2'>{params.data.baseamount}</p>
  </div>
}
const Cancel = (params) =>{
  return <div className='d-flex'>
  <p>{params.data.status}</p>
  {params.data.status === 'open' || params.data.status === 'waiting' ?
    <div className='pointer' onClick={()=>this.closeorder(params.data.orderid)}><SimpleLineIcon name='close pointer ml-2' size='1rem' color='red' /></div> : <></>
}
  </div>
}
const Daterender = (params) =>{
  return <div className='' >
  <span className=''>{params.data.date}</span>
  <span className='ml-2'>{params.data.time}</span>
  </div>
}
const Num = (params) =>{

  return <NumberFormat value={params.data.price} displayType='text' thousandSeparator=',' />
}
const Num2 = (params) =>{

  return <NumberFormat value={params.data.sl} displayType='text' thousandSeparator=',' />
}
const Numberbase = (params) =>{

  return <NumberFormat value={params.side === 'خرید' ? params.data.baseamount : params.data.quoteamount } displayType='text' thousandSeparator=',' />
}
const Pricereder = (params) =>{

  return <NumberFormat value={params.data.price } displayType='text' thousandSeparator=',' />
}
const Number = (params) =>{

  return <NumberFormat value={params.side === 'فروش' ? params.data.quoteamount : params.data.baseamount } displayType='text' thousandSeparator=',' />
}
const columnDefs = [
  {
    headerName : 'کد تراکنش' ,field: "orderid",
    onCellClicked: (params) => CustomButtonComponent(params)
  },
  { field: "date",headerName : 'تاریخ',cellRenderer: Daterender },
  { headerName : 'مارکت /ارز' , field: "market" ,cellRenderer: Marketrender,
  cellClass: "logoCell",},
  { headerName : 'مقدار' , field: 'baseamount' ,cellRenderer: Baserender },
  { headerName : 'مقدار' , field: 'quoteamount' ,cellRenderer:Quoterender },
  {headerName : 'قیمت' , field: "price",cellRenderer: Pricereder},

  { headerName : 'سمت/ نوع' , field: "side" ,cellStyle: cellStyle2 ,cellRenderer: this.Getstatus},
  { headerName : 'کارمزد' , field: "fee" },

  { headerName : 'وضعیت' , field: "status" , cellStyle: cellStyle,cellRenderer: Cancel},
]
const columnDef = [
  {
    headerName : 'کد تراکنش' ,field: "orderid",
    onCellClicked: (params) => CustomButtonComponent(params)
  },
  { field: "date",headerName : 'تاریخ',cellRenderer: Daterender },
  { headerName : 'ارز' , field: "market" ,cellRenderer: Slugrender,
  cellClass: "logoCell",},
  { headerName : 'مبدا / کارت' , field: "from"  },
  { headerName : 'مقصد /کارت' , field: "to"  },
  { headerName : 'مبلغ' , field: 'baseamount' ,cellRenderer: Numberbase },
  { headerName : 'وضعیت' , field: "status" , cellStyle: cellStyle,cellRenderer: this.Getstatus},
  { headerName : 'مشخصات / TX-ID' , field: "txid" , cellStyle: cellStyle},
]
const columnDefs2 = [
  {
    headerName : 'کد تراکنش' ,field: "orderid",
    onCellClicked: (params) => CustomButtonComponent(params)
  },
  { field: "date",headerName : 'تاریخ',cellRenderer: Daterender },
  { headerName : 'مارکت /ارز' , field: "market" ,cellRenderer: Slugrender,
  cellClass: "logoCell",},
  { headerName : 'قیمت' , field: "price" ,cellRenderer: Num },
  { headerName : 'SL / TP' , field: "sl" ,cellRenderer: Num2 },
  { headerName : 'سمت/ نوع' , field: "side" ,cellStyle: cellStyle2 },
  { headerName : 'دریافت - برداشت' , field: 'quoteamount' ,cellRenderer: Number },
  { headerName : 'پرداخت - واریز' , field: 'baseamount' ,cellRenderer: Numberbase },
  { headerName : 'وضعیت' , field: "status" , cellStyle: cellStyle,cellRenderer: Cancel},
]

    return(

    <>
    <Helmet>
       <title>رپیتکس | تراکنش ها</title>
    </Helmet>

    <Modal data-aos-duration={500} data-aos='slide-right' fade={false}  className='tx-detail' isOpen={this.state.modal} fade={false} toggle={this.toggle}>
    <div className='rtl'>
      <div className='d-flex rtl  my-auto text-dark bg-white'>
        <p className='py-5 px-3 rtl'>{t('tx-detaile-title')}</p>
        <p className='py-5 px-3 ltr mr-auto'>{i.orderid}</p>

      </div>
    </div>
    <ModalBody >
      <div className='card-body pt-5 bg-white light px-3' >
      <h6 className='d-flex rtl my-3'>
      <span className='rtl ml-auto '>{i.side }</span><span className='ltr mr-auto'>{i.market !== '' ? i.market : i.quote}</span></h6>
        <Row xl='2' lg='2' xs='1' sm='1' md='2' className='tx-row '>
          <Col xs='12' sm='12' md='6' xl='12' className={i.side === 'خرید' || i.side === 'واریز' ? "bg-primary mt-3" : "bg-danger mt-3"}>
          {i.type === 'تبدیل'  ?
          <div className='px-2 '>
          <Row xl='12'>
            <Col xs='4' className='my-3'>
            <small className={i.quotename.toLowerCase() +'-icon ml-auto float-right my-auto mr-3'} />
            </Col>
            <Col xs='4' className=' mt-4'>
            <small className='text-dark my-auto text-center font-weight-bold'>
            <NumberFormat  thousandSeparator=',' displayType='text' value={i.quoteamount} />
            </small>
            </Col>
            <Col xs='4' className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            {i.quotename}
            </small>
            </Col>
            <Col className='py-3'>
            <small className={i.basename.toLowerCase() +'-icon ml-auto float-right my-auto mr-3'} />
            </Col>
            <Col className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            <NumberFormat  thousandSeparator=',' displayType='text' value={i.baseamount} />
            </small>
            </Col>
            <Col className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            {i.basename}
            </small>
            </Col>

          </Row>
          </div> :
          <h4 className='text-middle font-weight-bold'>
          <span>
          {this.numeric(i.quoteamount)}
          </span>
          <span> : مبلغ
          </span>
          </h4>

           }
          </Col>
          <Col data-aos-delay='300' data-aos='fade-in' data-aos-duration='300' xl='12' xs='12' sm='12' md='12' lg='12' className=' mt-3'>
          <Row xl='1'>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light p-3 py-2 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            تاریخ :
            </small>
            <small className='text-small mr-auto'>
            {i.date}
            </small>
            </Col>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light  p-3 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            نوع :
            </small>
            <small className='text-small mr-auto'>
            {i.type}
            </small>
            </Col>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light  p-3 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            وضعیت :
            </small>
            <small className='text-small mr-auto'>
            {i.status}
            </small>

            </Col>

          </Row>

          </Col>
          <Col xl='12' xs='12' data-aos-delay='500' data-aos='fade-in' data-aos-duration='400'>
            <Row className='bg-light rtl  mt-5 py-4 p-3'>
              <Col className='py-4' xl='6' lg='6' md='6' sm='12' xs='12'>
                <small className='rtl'>  کد رهگیری : </small>
              </Col>
              <Col className='py-4 ltr' xl='6' lg='6' md='6' sm='12' xs='12'>
                <small className='ltr'>{i.txid !== '' ? i.txid : i.orderid}</small>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </ModalBody>
    <ModalFooter>
      <Button outline  color="dark" onClick={this.toggle}>{t('close')}</Button>
    </ModalFooter>
  </Modal>
        <Modal data-aos-duration={500} data-aos='slide-right' fade={false} isOpen={this.state.modals} className='main-tx-modal'>
          <div contentClassName ='tx-modal float-left bg-white'   isOpen={this.state.modal} className='tx-modal' >
          <ModalHeader className='d-none rtl bg-white' >
          <span className='ml-auto'>
          <SimpleLineIcon onClick={this.togglemodal} name='close ml-auto pointer' />
          </span>


          </ModalHeader>
            <div className='card-body pt-5 bg-white light' >
            <h4 className='text-muted rtl my-3'>{i.type + ' '+ i.market}</h4>
              <Row xl='2' lg='2' xs='1' sm='1' md='2' className='tx-row '>
                <Col className='bg-success mt-3'>
                {i.type === 'تبدیل'  ?
                <div className='px-2'>
                <Row xl='1'>
                  <Col className='py-2  rtl d-flex'>
                  <small className={i.quotename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-dark text-small mr-auto float-left font-weight-bold my-auto'>
                  {i.quoteamount}
                  </small>

                  </Col>
                  <Col className='text-dark mt-3 d-flex rtl border-top' >
                  <small className={i.basename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-dark text-small mr-Auto mt-3 font-weight-bold'>
                  {i.baseamount}
                  </small>
                  </Col>
                </Row>
                </div> :
                <h4 className='text-dark text-middle font-weight-bold'>
                <span className='text-dark'>
                {this.numeric(i.quoteamount)}
                </span>
                <span> : مبلغ
                </span>
                </h4>

                 }
                </Col>
                <Col className=' mt-3 '>
                <Row xl='1'>
                  <Col className='text-dark bg-light px-2 py-2 rtl d-flex'>
                  <small className='text-dark rtl ml-auto text-small' >
                  تاریخ :
                  </small>
                  <small className='text-dark text-small mr-auto'>
                  {i.date}
                  </small>
                  </Col>
                  <Col className='text-dark bg-light my-2 py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  نوع :
                  </small>
                  <small className='text-dark text-small mr-auto'>
                  {i.type}
                  </small>
                  </Col>
                  <Col className='bg-light  py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  وضعیت :
                  </small>
                  <small className='text-small mr-auto'>
                  {i.status}
                  </small>

                  </Col>

                </Row>

                </Col>

              </Row>
              <div className='bg-light rtl mr-3 mt-5 py-4 pl-3 pr-5 d-flex'>
              <span className='rtl'>  کد رهگیری : </span>
             <span className='mr-auto'>{i.txid !== '' ? i.txid : i.orderid}</span>
              </div>
            </div>
            <ModalFooter className='mt-auto rtl bg-white fixed-bottom' >
              <Button className='ml-auto' color="primary" onClick={this.togglemodal}>بستن</Button>
            </ModalFooter>
          </div>
       </Modal>
     {this.state.loader === true ?
       <>
         <div className='loader-home py-5'>
           <h3 className='text-center  m-auto mt-auto'><Loader /></h3>
         </div>
       </> :
       <Card>
       <CardHeader className='rtl '>
         <div class="d-flex">
         <a className={this.state.activetab === 0 ?  'mr-3 active-tab pointer text-center' : 'mr-3 text-center pointer'} onClick={()=>this.setactive(0)} >
         {t('transactions-tab0')}
         </a>
         <a className={this.state.activetab === 1 ? 'active-tab pointer text-center mx-2 ' : ' mx-2 text-center pointer'} onClick={()=>this.setactive(1)} >
         {t('transactions-tab1')}
         </a>
         <a className={this.state.activetab === 2 ? 'active-tab pointer text-center mx-2 ' : ' mx-2 text-center pointer'} onClick={()=>this.setactive(2)} >
         {t('transactions-tab2')}
         </a>
         <a className={this.state.activetab === 3? 'active-tab pointer text-center mx-2' : 'mx-2 text-center pointer'} onClick={()=>this.setactive(3)} >
         {t('transactions-tab3')}
         </a>
         <a className={this.state.activetab === 4? 'active-tab pointer text-center mx-2' : 'mx-2 text-center pointer'} onClick={()=>this.setactive(4)} >
         {t('transactions-tab4')}
         </a>
         <a className={this.state.activetab === 5? 'active-tab pointer text-center mx-2' : 'mx-2 text-center pointer'} onClick={()=>this.setactive(5)} >
         {t('transactions-tab5')}
         </a>
       </div>
       </CardHeader>
       <CardBody style={{ height: 520 }}>
       <AgGridReact
       className={theme === 'dark' ? "ag-theme-quartz-dark" : "ag-theme-quartz"
             }
         size='small'
         responsive
         ref ={this.gridRef}
         rowData={this.data}
         suppressExcelExport={true}
         columnDefs={this.state.activetab === 5  ? columnDefs2 :
            this.state.activetab === 0 || this.state.activetab === 1 || this.state.activetab === 4? columnDefs :  columnDef}
         defaultColDef={defaultColDef}
         suppressRowClickSelection={true}
         pagination={true}
         paginationPageSize={10}
         paginationPageSizeSelector={[10, 30, 40,50,60,100]}
       />
        </CardBody>
      </Card> }

    </>
    )
  }
  }
  export default withTranslation()(Dashboard)
