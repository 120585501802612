import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Card,Label,FormText, CardHeader,FormGroup,Table, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'
import Select from 'react-select';
import Loader from '../../landing/loader'
import SearchInput, {createFilter} from 'react-search-input'
import QRCode from "react-qr-code";
import { PiCopyThin } from "react-icons/pi";
import Toast from 'awesome-toast-component'
import { IoCheckmarkDoneSharp } from "react-icons/io5";
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      key : '1' ,
      address : 'rapitex.com',
      list : [],
      c1 : [],
      chain : false,
      loader : true,
      chains : [],
      coinname : '',
      coin : '',
      selectedOption : [],
      selectedOption2 : [],
      trc20depositfee : '  2 USDT'
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(!uuid) {
      this.props.history.push('/auth/login')
    }
    this.getwallet()
  }
  getwallet =()=>{

    this.setState({mode : 2})
    axios.get('/api/deposit/list').then(response=>{
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
        return
      }
      this.setState({list :  response.data ,loader : false})
    })

  }


  setcointaker = (item) =>{
    this.setState({coinname : item.irname , coinPrice : item.irprice , fee : item.fee,c1 : item})
    // this.copyaddress()
  }
  copyaddress =()=>{
    const { t } = this.props
    if(this.state.hsaddress === true){
      navigator.clipboard.writeText(this.state.address)
      new Toast(`${t('copied')}`, {
        style: {
            container: [
                ['background-color', 'green']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
          position : 'top'
    })
  }

  }

  chargewallet =()=>{
    const { t } = this.props;
    if(this.state.verification === "0"){
      new Toast(`${t('verification.need')}`, {
        style: {
            container: [
                ['background-color', 'blue']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log('امکان واریز قبل از احراز هویت مقدور نیست')
      return
    }
    if (!this.validator.fieldValid('amount')) {
      new Toast(`${t('enter.true.amount')}`, {
        style: {
            container: [
                ['background-color', 'blue']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log('مبلغ را به صورت صحیح وارد کنید')
      return
    }else{

    }
    let amount = this.state.amount
    let name = this.state.name
    let email = this.state.email
    let phone = this.state.mobile
    var url = 'https://idpay.ir/zarrinpayment?amount='+amount +'&name=' + name + '&desc='+email + '&phone='+phone
    window.open(url, "_blank")
  this.setState({modal : !this.state.modal})
  }
  onchange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
componentDidMount(){
  window.scroll(0,0)
}
  handleChange = (selectedOption) => {
    const { t } = this.props;
     var symbol = selectedOption.currency
   this.setState({ selectedOption : selectedOption ,currentsymbol : selectedOption.currency ,chainName : selectedOption.chainName,chain : selectedOption.chain ,selectcoin : false , selected : false});

   var url = '/api/deposit/deposit_address/'+symbol
    axios.get(url).then(response=>{
      if(response.data.status === 'error-fatal'){
        this.props.history.push('/auth/login')
        return
      }
      this.setState({address : response.data.data.address , minimum :response.data.data.minimum , hsaddress : true })
    })
 }
  trc20usdt = () => {
    const { t } = this.props;
    var taker = this.state.selectedOption.taker
    var chainname = 'TRC20'
   this.setState({  chainName : chainname, selected : true , taker : 'USDT'});

   var url = '/api/wallet/trc20usdt/'
   axios.get(url).then(response=>{
     if(response.data.status === 'error-fatal'){
       this.props.history.push('/auth/login')
       return
     }
     if(response.data.status === 'error'){
       new Toast(`${t(response.data.message)}`, {
         style: {
             container: [
                 ['background-color', 'blue']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })
       console.log(response.data.message)
       this.setState({address : ''})
       return
     }
     this.setState({address : response.data.address , hsaddress : true})
   })
 }
  handlechain = (selectedOption2) => {
    const { t } = this.props;
    var taker = this.state.selectedOption.taker
    var chainname = selectedOption2.chainName
    var chain = selectedOption2.chain
   this.setState({ selectedOption2 , chainName : chainname, selected : true });

   var url = '/api/wallet/check_account_status/'+taker +'/'+chain + '/'+chainname
   axios.get(url).then(response=>{
     if(response.data.status === 'error-fatal'){
       this.props.history.push('/auth/login')
       return
     }
     if(response.data.status === 'error'){
       new Toast(`${t(response.data.message)}`, {
         style: {
             container: [
                 ['background-color', 'blue']
             ],
             message: [
                 ['color', '#eee'],
             ],
             bold: [
                 ['font-weight', '900'],
             ]
         },
         timeout : 3000,
         position : 'top'
     })
       console.log(response.data.message)
       this.setState({address : ''})
       return
     }
     this.setState({address : response.data.data.address , hsaddress : true})
   })
 }
setCoin =(e)=>{
  this.setState({coin : e.target.value.toUpperCase()})
}
settxid =(e)=>{
  let txid = e.target.value
  let formdata = new FormData()
  const {t} = this.props
  formdata.append('txid',txid)
  formdata.append('currency','USDT')
  formdata.append('chainName','TRC20')
  formdata.append('chain','trx')
  axios.post('/api/wallet/submit_txid' , formdata).then(response=>{
    if(response.data.status === 'error-fatal'){
      this.props.history.push('/auth/login')
      return
    }
    if(response.data.status === 'error'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'blue']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
      console.log(response.data.message)
      this.setState({address : ''})
      return
    }
    if(response.data.status === 'success'){
      new Toast(`${t(response.data.message)}`, {
        style: {
            container: [
                ['background-color', 'success']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
    }
  })
}
  render(){
    const data = this.state.list
    const filter = data.filter(item => item.currency.includes(this.state.coin));
    const { t } = this.props;
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>واریز ارز دیجیتال | زرین تتر</title>
    </Helmet>
    {this.state.loader === true ?
      <>
        <div className='loader-home py-5'>
          <h3 className='text-center m-auto mt-auto'><Loader /></h3>
        </div>
      </> : <>
    <div className='mt-3 rtl'>


          <Card className=''>
          <CardHeader>
            <div className='d-flex rtl'>
             <p onClick={()=>this.setState({type : 1})} className={this.state.type === 1 ? 'pointer' : 'pointer' }>واریز</p>
            </div>
          </CardHeader>
          <CardBody className='pb-5'>
            <Row>
            <Col  className='mx-auto' xl='6' lg='6' xs='12' md='12' sm='12'>

              <div   className='rtl p-3'>
              <FormGroup className='my-3'>
                <Label className='text-medium' for="name">توکن / ارز</Label>
                <UncontrolledDropdown className='bg-light ticker-list-2 m-auto'>
                  <DropdownToggle color='light' className='py-2 px-3 text-center pointer' block>
                {this.state.selectcoin !== false ? <span className='my-auto'>توکن را انتخاب کنید</span> :
                <div className='d-flex justify-content-space-between'>
                  <div className={this.state.selectedOption.currency+'-icon'} />
                  <span className='mr-2 my-auto selected-text'>{this.state.selectedOption.chainName}</span>
                  <span className='mr-auto my-auto selected-text'>{this.state.selectedOption.slug}</span>
                </div>
               }
                  </DropdownToggle>

                  <DropdownMenu className='w-100 modifier'>
                  <div className='mt-2 mb-1 d-flex rtl'>
                    <Input placeholder={t('search token')} value={this.state.coin} onChange={this.setCoin} name='coin' className='ltr' />
                  </div>
                  {filter.map((item,index)=>
                    <>
                      <DropdownItem onClick={()=>this.handleChange(item)}>
                      <div className='d-flex justify-content-space-between'>
                        <span className={item.slug+'-icon'}></span>
                        <span className='mr-2 text-muted text-medium mt-2 selected-text'>{item.slug}</span>
                        <span className='text-medium mr-auto mt-2 selected-text'>{item.chainName}</span>
                      </div>
                      </DropdownItem>
                    </>
                  )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <FormText className='mt-2'>{t('depositnotice1')}</FormText>
               </FormGroup>

              </ div>



              <div>

              </div>
              <div className='p-3 add-container mt-3' dir='ltr'>
                <InputGroup>


                  <Input disabled value={this.state.address} placeholder="" className=' text-selected' />
                  <div style={{'height' : '50px'}} color='light' outline className='my-auto p-3 input-custome-button text-dark' onClick={this.copyaddress}>
                    <PiCopyThin className='text-dark' />

                  </div>
                </InputGroup>
              </div>

              <div className='mt-3 text-center  qrcode-container'>
              <QRCode value={this.state.address} />
               </div>
            </Col>
            <Col xl='5' className='info-col'>
              <p className='p-3 text-dark'>
              نکات مهم قبل از انجام واریز:
              </p>
              <p className='p-3 text-medium text-dark'>
                لطفاً پیش از انجام واریز رمزارز، دقت کنید که آدرس واریزی خود را صحیح و با دقت وارد کنید. اگر آدرس نادرست وارد شود، امکان دسترسی به دارایی شما وجود ندارد.              </p>
                <p className='p-3 text-medium text-dark'>
                  هرگونه واریز کوین غیر از کوین انتخابی به آدرس مشخص شده، منجر به از دست رفتن دارایی و عدم دسترسی به آن خواهد شد.              </p>
                <p className='p-3 text-medium text-dark'>
                  لطفاً پیش از شروع معامله در رپیتکس، اطمینان حاصل کنید که فرآیند احراز هویت شما تکمیل شده و کیف پول شما شارژ شده باشد.              </p>
                <p className='p-3 text-medium text-dark'>
              از انجام واریز مستقیم به آدرس خود از طریق اکسچنج‌های جهانی که در شرایط استفاده از خدمات خود، کاربران ایرانی را محدود کرده‌اند، به ویژه از اکسچنج‌های آمریکایی مانند کوین‌بیس و کراکن، خودداری کنید. در صورت لزوم برای انتقال،از کیف پول شخصی و آدرس‌های یک بار مصرف و انتقال چند لایه بین آدرس‌ها استفاده نمایید.
                </p>
                </Col>
            </Row>
            </CardBody>
            </Card>
    </div>
    </>}

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
