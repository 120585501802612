import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col} from 'reactstrap'
import {BsTelegram,BsWhatsapp,BsYoutube,BsTwitter,BsLinkedin,BsInstagram} from 'react-icons/bs'
import aparat from '../../style/images/aparat.svg'
import Helmet from 'react-helmet'
import logo from '../../style/images/logo.png'
import tvlogo from '../../style/images/tvlogo.svg'


const Section1 =(props)=>{
  const { t } = useTranslation();
  return(
    <div className='pb-5'>
    <a href='https://www.tradingview.com/crypto-screener/'>
      <Row xs='1' sm='1' md='2' lg='2' xl='2' className='mt-3 mb-5 rtl'>
        <Col xl='2' lg='2' md='4'>
          <img className='tvlogo mx-3' src={tvlogo} />
        </Col>
        <Col xl='10' lg='10' md='9'>
          <h6 className='my-auto mx-3 d-block'>{t('tradingview')}</h6>
        </Col>
    </Row>
    </a>
      <section id='' className='rtl  px-3 py-4 lh-xl '>
      <Row xl='3' lg='3' md='3' sm='2' xs='2'>
          <Col xs='12' className='footer-links' >
          <Link to='/' className='font-weight-bold small text-small' >صفحه اصلی</Link>
          <Link to='/about-us' className='small text-small' >درباره ما</Link>
          <Link to='/Terms-and-Condition' className='small text-small' >قوانین و مقررات</Link>
          <Link to='/currencies' className='small text-small' >لیست ارزها</Link>
          <Link to='/fees' className='small text-small ' >کارمزد</Link>
          <Link to='/faq' className='small text-small' >سوالات متداول</Link>
        </Col>

        <Col className='footer-links'>
        <Link to='/' className=' p' >
          <span className='mr-2 font-weight-bold'>
          خرید سریع
          </span>
        </Link>
        <Link to='/panel/exchange/buy/usdt' className='small text-small' >
          <span className='usdt-icon small-icon d-block'/>
          <span className='mr-2'>
                خرید تتر
          </span>
        </Link>
        <Link to='/panel/exchange/buy/btc' className='small text-small'>
          <span className='btc-icon small-icon d-block'/>
          <span className='mr-2'>
            خرید  بیت کوین
          </span>
        </Link>
        <Link to='/panel/exchange/buy/eth' className='small text-small' >
          <span className='eth-icon small-icon d-block'/>
          <span className='mr-2'>
            خرید اتریوم
          </span>
        </Link>
        <Link to='/panel/exchange/buy/bnb' className='small text-small'>
          <span className='bnb-icon small-icon d-block'/>
          <span className='mr-2'>
            خرید بایننس
          </span>
        </Link>
        <Link to='fees' className='small text-small'>
          <span className='paxg-icon small-icon d-block'/>
          <span className='mr-2'>
            خرید طلاـPAXG
          </span>
        </Link>

        </Col>
        <Col xs='6' sm='12' md='3' lg="2" xl="2"  className='footer-links'>
        <Link to='/' className=' p' >
          <span className='mr-2 font-weight-bold'>
          فروش سریع
          </span>
        </Link>
        <Link to='/panel/exchange/sell/usdt' className='small text-small' >
          <span className='usdt-icon small-icon d-block'/>
          <span className='mr-2'>
                فروش تتر
          </span>
        </Link>
        <Link to='/panel/exchange/sell/btc' className='small text-small'>
          <span className='btc-icon small-icon d-block'/>
          <span className='mr-2'>
            فروش  بیت کوین
          </span>
        </Link>
        <Link to='/panel/exchange/sell/eth' className='small text-small' >
          <span className='eth-icon small-icon d-block'/>
          <span className='mr-2'>
            فروش اتریوم
          </span>
        </Link>
        <Link to='/panel/exchange/sell/bnb' className='small text-small'>
          <span className='bnb-icon small-icon d-block'/>
          <span className='mr-2'>
            فروش بایننس
          </span>
        </Link>
        <Link to='/panel/exchange/sell/paxg' className='small text-small'>
          <span className='paxg-icon small-icon d-block'/>
          <span className='mr-2'>
            فروش طلاـPAXG
          </span>
        </Link>

        </Col>
        <Col xs='6' sm='12' md='3' lg="2" xl="2"  className='footer-links d-none'>
        <Link to='/' className=' p' >
          <span className='mr-2 font-weight-bold'>
          راهنمای سایت
          </span>
        </Link>
        <Link to='/' className='small' >
          <span className='mr-2 '>
            ثبت نام
          </span>
        </Link>
        <Link to='/about-us' className='small text-small'>
          <span className='mr-2'>
            خرید ارز دیجیتال
          </span>
        </Link>
        <Link to='/Terms-and-Condition' className='small text-small' >
          <span className='mr-2'>
            واریز و برداشت ریالی
          </span>
        </Link>
        <Link to='/currencies' className='small text-small'>
          <span className='mr-2'>
            احراز هویت
          </span>
        </Link>
        <Link to='/fees' className='small text-small'>
          <span className='mr-2'>
            معاملات حرفه ای
          </span>
        </Link>
        <Link to='/faq' className='small text-small' >


        </Link>
        </Col>

        <Col xs='12' sm='12' md='3' lg="4" xl="4"  className='mx-auto my-2' >
          <p className='font-weight-bold rtl'>
            ما را در شبکه های اجتماعی دنبال کنید:
          </p>
          <div className='d-flex py-2'>
             <a className='ml-auto' target="_blank" href={t('telegram-link')}><BsTelegram size='1.5rem' color='light' className='telegram' /></a>
             <a className='mx-auto' target="_blank" href={t('whatsapp-link')}>  <BsWhatsapp size='1.5rem' color='green' className='whatsapp'/></a>
             <a className='mx-auto' target="_blank" href={t('instagram.link')}>  <BsInstagram size='1.5rem' color='red' className='instagram'/></a>
             <a className='mr-auto' target="_blank" href={t('instagram.link')}>  <img src={aparat} width='32px' className='aparat'/></a>
          </div>
          <div  className='footer-logo-container mt-5 mr-auto' />
          <div className=''>
          <li className='mt-3 text-medium address rtl'><a>{props.address}</a></li>
          <li className='text-medium phone rtl'><a>{props.phone}</a></li>
          </div>
        </Col>

      </Row>






      </section>

    </div>
  )
}
export default withTranslation()(Section1);
